import type { StyleParamValue } from 'root/components/Menus/stylesParams';
import { Preset } from 'root/utils/presets';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';

export const useGetNavigationFullWidthTab = (preset: Preset) => {
  const {
    gridMenuNavigationFullWidthTab,
    columnsMenuNavigationFullWidthTab,
    stripMenuNavigationFullWidthTab,
    centerMenuNavigationFullWidthTab,
  } = useGetLayoutStylesParams();

  const navigationFullWidthTabMap: Record<Preset, StyleParamValue> = {
    [Preset.Grid]: gridMenuNavigationFullWidthTab,
    [Preset.Columns]: columnsMenuNavigationFullWidthTab,
    [Preset.Center]: centerMenuNavigationFullWidthTab,
    [Preset.Strip]: stripMenuNavigationFullWidthTab,
  };

  return navigationFullWidthTabMap[preset];
};
