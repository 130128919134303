import { Alignment } from 'root/consts';
import { getAlignmentText } from 'root/utils/alignment';
import { Preset } from 'root/utils/presets';
import { useGetAlignment } from 'root/hooks/useGetAlignment';

export const useGetNavigationAlignment = (preset: Preset) => {
  const gridMenuNavigationAlignment = useGetAlignment('gridMenuNavigationAlignment');
  const columnsMenuNavigationAlignment = useGetAlignment('columnsMenuNavigationAlignment');
  const stripMenuNavigationAlignment = useGetAlignment('stripMenuNavigationAlignment');

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridMenuNavigationAlignment,
    [Preset.Columns]: columnsMenuNavigationAlignment,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: stripMenuNavigationAlignment,
  };

  return alignmentMap[preset];
};
