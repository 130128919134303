import { useSettings } from '@wix/tpa-settings/react';
import { useMemo, useState } from 'react';
import type { PopulatedMenu } from 'root/apiTypes';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { getMenusToDisplay } from './utils';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { settingsParams } from 'root/components/Menus/settingsParams';
import { getMenuToDisplayFromUrl } from 'root/utils/navigation';
import { type ILocation } from '@wix/yoshi-flow-editor';

export const useMenusLogic = ({ menus, urlQuery }: { menus: PopulatedMenu[]; urlQuery: ILocation['query'] }) => {
  const settings = useSettings();
  const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];

  const displayableMenus = useMemo(() => {
    if (!menusDisplayOrder) {
      return menus;
    }
    return getMenusToDisplay({ menusDisplayOrder, menus });
  }, [menus, menusDisplayOrder]);

  const [activeTab, setActiveTab] = useState(() => getMenuToDisplayFromUrl({ urlQuery, displayableMenus }));

  const { showTabs } = useGetDisplayStylesParams();

  const shouldShowTabs = displayableMenus.length > 1 && showTabs;

  return {
    activeTab,
    displayableMenus,
    shouldShowTabs,
    menusDisplayOrder,
    setActiveTab,
  };
};
