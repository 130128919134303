import { Alignment } from 'root/consts';

export const getAlignmentText = (alignment: Alignment): string => {
  switch (alignment) {
    case Alignment.Center:
      return 'center';
    case Alignment.Right:
      return 'right';
    case Alignment.Left:
    default:
      return 'left';
  }
};
