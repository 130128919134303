import type { CSSProperties } from 'react';
import { Alignment } from 'root/consts';
import { getAlignmentText } from 'root/utils/alignment';
import { Preset } from 'root/utils/presets';
import { useGetAlignment } from 'root/hooks/useGetAlignment';

export const useGetMenuTextAlignment = (preset: Preset) => {
  const gridMenuAlignment = useGetAlignment('gridMenuAlignment');
  const columnsMenuAlignment = useGetAlignment('columnsMenuAlignment');
  const stripMenuAlignment = useGetAlignment('stripMenuAlignment');

  const textAlignCssMap: Record<Preset, string> = {
    [Preset.Grid]: gridMenuAlignment,
    [Preset.Columns]: columnsMenuAlignment,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: stripMenuAlignment,
  };

  return { textAlign: textAlignCssMap[preset] as CSSProperties['textAlign'] };
};
