import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { useGetMenuTextAlignment } from 'root/hooks/preset/useGetMenuTextAlignment';

export const useMenuLogic = () => {
  const { showMenuName, showMenuDescription } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();

  const textAlignCss = useGetMenuTextAlignment(preset);

  return { showMenuName, showMenuDescription, textAlignCss };
};
