import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { CSSProperties } from 'react';
import { Alignment } from 'root/consts';
import { getAlignmentText } from 'root/utils/alignment';
import { Preset } from 'root/utils/presets';
import { useGetAlignment } from 'root/hooks/useGetAlignment';

export enum AlignmentProperty {
  JustifySelf = 'justifySelf',
  TextAlign = 'textAlign',
}

export const useGetItemTextAlignment = (preset: Preset, alignmentProperty: AlignmentProperty) => {
  const { isRTL } = useEnvironment();
  const gridItemAlignment = useGetAlignment('gridItemAlignment');

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridItemAlignment,
    [Preset.Columns]: getAlignmentText(isRTL ? Alignment.Right : Alignment.Left),
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: getAlignmentText(isRTL ? Alignment.Right : Alignment.Left),
  };

  const stripGridTemplateColumns = {
    gridTemplateColumns: '1fr 20px auto 20px calc(var(--stripItemImageSize) * 1%)',
  };

  const columnsGridTemplateColumns = {
    gridTemplateColumns: '1fr 20px auto 20px calc(var(--columnsItemImageSize) * 1%)',
  };

  const alignment = {
    [alignmentProperty]: alignmentMap[preset] as CSSProperties[typeof alignmentProperty],
    ...(preset === Preset.Strip && alignmentProperty === AlignmentProperty.TextAlign && stripGridTemplateColumns),
    ...(preset === Preset.Columns && alignmentProperty === AlignmentProperty.TextAlign && columnsGridTemplateColumns),
  };

  return alignment;
};
