import React from 'react';

export const Size_S = () => {
  return (
    <svg fill="none" height="38" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
      <path
        className="illus-clr-1"
        d="m13.528 23.128 1.456-1.456a4.57 4.57 0 0 0 1.616 1.264c.672.31 1.37.464 2.096.464.779 0 1.392-.144 1.84-.432.459-.299.688-.688.688-1.168 0-.427-.208-.768-.624-1.024-.405-.267-1.136-.48-2.192-.64-1.547-.235-2.667-.619-3.36-1.152-.693-.533-1.04-1.248-1.04-2.144 0-.693.192-1.307.576-1.84.395-.533.933-.95 1.616-1.248.683-.299 1.461-.448 2.336-.448 1.024 0 1.936.197 2.736.592a6.21 6.21 0 0 1 2.032 1.536l-1.44 1.44a4.31 4.31 0 0 0-1.44-1.232 3.803 3.803 0 0 0-1.856-.464c-.693 0-1.259.139-1.696.416-.437.277-.656.65-.656 1.12 0 .373.192.667.576.88.395.213 1.11.4 2.144.56 1.547.235 2.677.64 3.392 1.216.725.565 1.088 1.339 1.088 2.32 0 .725-.203 1.36-.608 1.904-.405.533-.965.95-1.68 1.248-.715.288-1.541.432-2.48.432-1.099 0-2.08-.197-2.944-.592-.864-.395-1.59-.912-2.176-1.552Z"
        fill="#116DFF"
      />
    </svg>
  );
};
