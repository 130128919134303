import { useMemo } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { hasItemDivider } from 'root/utils/presets';
import type { ItemProps } from './Item';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import type { MenuCheckbox } from 'root/clientTypes';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import type { ItemImage } from 'root/apiTypes';
import { AlignmentProperty, useGetItemTextAlignment } from 'root/hooks/preset/useGetItemTextAlignment';
import { useGetImageWidth } from 'root/hooks/preset/useGetImageWidth';

export const useItemLogic = ({ item }: ItemProps) => {
  const { sectionId } = useItemsContext();
  const {
    showItemDescription,
    showItemPrice,
    showItemZeroPrice,
    showItemCurrency,
    showItemLabels,
    showItemImage,
    showItemDividers,
  } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();
  const {
    checkPricingOptionsMenus,
    checkImagePlaceholderMenus,
    imagePlaceholder,
    showZeroPricingForAllMenus,
    showItemImagePlaceholder,
    showImagePlaceholderForAllMenus,
  } = useGetDisplaySettingsParams();

  const textAlignCss = useGetItemTextAlignment(preset, AlignmentProperty.TextAlign);

  const hasPriceVariants = (item.priceVariants?.variants ?? []).length > 0;

  const priceContent = showItemCurrency
    ? item.priceInfo?.formattedPrice
    : item.priceInfo?.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPricingForAllMenus || isSectionChecked(checkPricingOptionsMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowPrice = isPriceShowed({
    price: item.priceInfo?.price ?? '',
    showPrice: showItemPrice,
    showZeroPrice: showItemZeroPrice,
    sectionChecked: priceOptionSectionChecked as boolean,
  });

  const shouldShowItemDividers = showItemDividers && hasItemDivider(preset);
  const shouldShowLabels = item.labels && item.labels.length > 0 && showItemLabels;

  const imagePlaceholderSectionChecked =
    showImagePlaceholderForAllMenus || isSectionChecked(checkImagePlaceholderMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowImagePlaceholder = useMemo(
    () => (showItemImagePlaceholder ? imagePlaceholderSectionChecked : false),
    [imagePlaceholderSectionChecked, showItemImagePlaceholder]
  );
  const shouldShowItemImage = showItemImage && (item?.image || shouldShowImagePlaceholder);
  const itemImage = useMemo(
    () => (item?.image ? item?.image : shouldShowImagePlaceholder ? imagePlaceholder : undefined) as ItemImage,
    [imagePlaceholder, item?.image, shouldShowImagePlaceholder]
  );

  const imageWidth = useGetImageWidth(preset, itemImage);

  return {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    shouldShowPrice,
    shouldShowItemImage,
    textAlignCss,
    shouldShowLabels,
    shouldShowItemDividers,
    shouldShowImagePlaceholder,
    itemImage,
    imageWidth,
  };
};
