import { Size_L } from 'root/assets/icons/ImageSize/Size_L';
import { Size_M } from 'root/assets/icons/ImageSize/Size_M';
import { Size_S } from 'root/assets/icons/ImageSize/Size_S';
import { ImageSize, ImageSizeCategories } from 'root/consts';

export const getImageSizeValue = (size: ImageSizeCategories, isMobile?: boolean) => {
  switch (size) {
    case ImageSizeCategories.SMALL:
      return {
        value: isMobile ? ImageSize.MOBILE[ImageSizeCategories.SMALL] : ImageSize.DESKTOP[ImageSizeCategories.SMALL],
        image: Size_S,
        title: '',
      };
    case ImageSizeCategories.MEDIUM:
      return {
        value: isMobile ? ImageSize.MOBILE[ImageSizeCategories.MEDIUM] : ImageSize.DESKTOP[ImageSizeCategories.MEDIUM],
        image: Size_M,
        title: '',
      };
    case ImageSizeCategories.LARGE:
      return {
        value: isMobile ? ImageSize.MOBILE[ImageSizeCategories.LARGE] : ImageSize.DESKTOP[ImageSizeCategories.LARGE],
        image: Size_L,
        title: '',
      };
  }
};
