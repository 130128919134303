import React from 'react';

export const Size_M = () => {
  return (
    <svg fill="none" height="38" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
      <path
        className="illus-clr-2"
        d="M20.144 23.768h-1.6L15.2 16.776V25h-2.08V13.56h2.496l3.744 7.824 3.728-7.824h2.464V25H23.44v-8.112l-3.296 6.88Z"
        fill="#116DFF"
      />
    </svg>
  );
};
